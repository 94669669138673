.header-page{
    align-items: center;
    height: calc(100vw * 0.55);
    background-image: url('./background.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-clip: content-box;
    background-origin: padding-box;
}

.header-vid{
    width: 50vw;
    padding: 50px;
}

.header-title{
    color: rgb(255, 255, 255);
    font-size: 5vw;
}

.headerTitle-container{
    margin-bottom: 15px
}

.header-subtitle{
    color: rgb(255, 255, 255);
    font-size: 2vw;
    font-weight: 300;
}

.header-container{
    margin: auto;
}

.headerText-container{
    margin-left: 5vw;
    margin-top: 5vw;
}

@media only screen and (min-width: 1000px){
    .header-page {
        transform: translateY(-75px);
    }
}
 
@media only screen and (min-width: 600px) {
    .header-page {
        transform: translateY(-50px);
    }
}

@media only screen and (max-width: 700px) {
    .header-page {
        transform: translateY(-10px);
    }

    .header-title{
        font-size: 7vw;
    }

    .header-subtitle{
        font-size: 3vw;
    }
}

@media only screen and (max-width: 1000px){
    .header-vid{
        display: none;
    }
}

@media only screen and (max-width: 400px){
    .headerText-container{
        margin-top: 10vw;
    }
}

