.title{
    font-weight: 400;
    margin-bottom: 10px;
}

.question-container{
    background-color: white;

    margin: 0 auto;
    padding: 15px 20px;

    border-radius: 15px;

    max-width: 60%;
    min-width: 60%;
}

.question{
    margin-bottom: 20px;
}

.description {
    margin: 30px 0;
}

.button-container{
    margin: 0 10px;
    width: 220px;
}

.start-button {
    padding: 10px 15px;
    background-color: var(--dark-text);
    color: var(--light-text);
    border: 0;
}

.practiceInput-container{
    padding: 10px;
    min-width: 500px;
    border-top: 1px solid rgb(224, 224, 224);
    font-family: 'Poppins', sans-serif;
}

.practiceInput-container:hover{
    cursor: pointer;
}

.practiceInputContainer-selected{
    background-color: var(--primary-color);
    color: white !important;
}

.practiceInputText-selected{
    color: white;
}

.practiceDescription-container{
    max-width: 700px;
}

.practiceStat-container{
    margin: 20px 0;  
}

.practiceButton-container{
    width: 200px;
    margin-right: 20px; 
}

.practiceQuestion-container{
    margin-bottom: 30px;
    min-width: fit-content;
}

.practicePage-userResponse{
    margin-left: 10px !important;
}

.timer-container{
    font-family: 'Poppins', sans-serif;
    margin: 0 30px;
}

.practicePageButton-container{
    min-width: fit-content;
}

.practicePageKnowledgeArea-title{
    text-align: center;
    margin: 10px 0;
}

.practicePage-title{
    margin-bottom: 20px;
    font-weight: 400;
}

.practicePage-subtitle{
    margin: 10px 0;
    font-weight: 400;   
}

.practicePageReview-title{
    display: flex;
    align-items: center;
    margin-right: 100px;
}

.practicePage-correct{
    color: rgb(78, 255, 58);
}

.practicePage-incorrect{
    color: rgb(255, 0, 0);
}

.practicePage-skipped{
    color: rgb(152, 152, 152);
}

.practicePageReview-page{
    margin: 20px 0;
    max-width: 800px;
    min-width: 800px;
}

.practicePageReviewTitle-container{
    display: flex;
    flex-direction: row;
}

.practicePageExplanation-title{
    font-size: 20px;
    font-weight: 400;

    margin: 20px 0 5px 0;
}

.practicePageExplanation-desc{
    font-size: 16px;
}

.practiceIntro-bar{

}

.practicePageReviewButton-container{
    width: 200px;
}

.practiceIntro-image{
    width: 400px;
}

.practiceImage-container{
    margin: 15px 0;
}

.practiceIntroButton-container{
    display: flex;
    flex-direction: row;
    padding: 150px 0;
    justify-content: center;
}

.practiceIntro-container{
    height: 100vh;
    overflow-y: scroll;
}

@media screen and (max-width: 400px){
    .practiceIntro-image{
        width: 100%;
    }
}

@media screen and (max-width: 500px){
    .practicePageReview-page{
        max-width: 275px;
    }
}

@media screen and (max-width: 650px){
    .practiceStat-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .practiceIntro-bar{
        display: none;
    }

    .practiceIntro-stat{
        margin: 5px 0;
    }
}

@media screen and (max-width: 700px){
    .practiceIntro-container{
        height: 125vh;
    }

    .practicePageReview-page{
        max-width: 400px;
    }
    
    .practiceIntroButton-container{
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }

    .practicePageReviewTitle-container{
        flex-direction: column;
        align-items: center;
    }

    .practicePageReview-title{
        margin: 0;
    }
}

@media screen and (max-width: 900px){
    .practicePageReview-page{
        max-width: 600px;
    }
}