.statsTableRow-pass{
    background-color: rgb(181, 250, 77);
}

.statsTable-header{
    border-bottom: 4px solid var(--highlight-color);
}

.statsTable-head{
    min-width: 150px
}

.stats-table{
    min-width: 100%;
    max-height: 300px;       /* Set the maximum height for the container */
    overflow-y: auto;        /* Enable vertical scrolling */
    overflow-x: hidden;      /* Disable horizontal scrolling */
    border: 1px solid #ccc;  /* Optional: Add border for container */
}

.stats-table table {
    width: 100%;             /* Ensure the table takes full width */
    border-collapse: collapse; /* Ensure there are no gaps between cells */
}


.stats-table thead {
    position: sticky;        /* Make the header sticky */
    top: 0;                  /* Stick to the top */
    background-color: #fff;  /* Background color for header */
    z-index: 2;              /* Ensure it stays above body rows */
}

.overflow-container::-webkit-scrollbar {
    width: 10px; /* Set the width of the scrollbar */
}

.overflow-container::-webkit-scrollbar-thumb {
    background-color: #888; /* Set the color of the scrollbar thumb */
    border-radius: 5px; /* Set the border radius of the scrollbar thumb */
}

.overflow-container::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Set the color of the scrollbar thumb on hover */
}

.overflow-container::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Set the color of the scrollbar track */
    border-radius: 5px; /* Set the border radius of the scrollbar track */
}

/* New */

.statsPage-title{
    color: var(--widget-grey);
    margin-bottom: 50px;
    font-size: 36px;
}

.statsPage-widget{
    border-radius: 10px;
    margin: 10px;
    min-width: fit-content;
    padding: 0 20px 20px 20px;
}

.statsPage-widget h1 {
    color: var(--primary-color);
    font-size: 26px;
    margin: 20px;
}

.percent60-width{
    width: 60%;
}

.percent40-width{
    width: 40%;
}

.statsPage-disabled {
    opacity: 0.8;
}