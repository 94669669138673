.trainingTest-container{
    background-color: white;
    border: 1px solid white;
    border-radius: 10px;
    padding: 30px 70px;
    margin: 50px 0;
}

.trainingTest-select{
    padding: 10px;
    border: 1px solid white;
    border-radius: 5px;
    margin: 5px;
    min-width: 100px;
}

.trainingTest-row{
    max-height: 50px;
}

.trainingTest-cell{
    text-align: left;
}

.trainingTest-title{
    margin-bottom: 30px;
}

.trainingTestSelect-row{
    align-items: center;
    min-height: 50px;
}

.trainingPageTime-input{
    margin-left: 20px;
}

.trainingPageTrainingText-container{
    margin: -5px 7px 0 15px;
}
