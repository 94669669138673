.login-form{
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: white;

    border-radius: 3px;
}

.login-container{
    width: 400px
}

.loginForm-container{
    width: 30vw;
}

.loginFormOr-container {
    display: flex;
    justify-content: space-between; /* Distribute space between left and right */
    align-items: center; /* Vertically center the content */
}

.loginForm-or{
    margin: 10px;
}

.loginFormOr-left {
    flex: 1;
}

.loginFormOr-right {
    flex: 1;
}

.loginFormAuth-container {
    margin: 10px 0;
}

.loginTitle-text{
    margin-bottom: 70px;
    margin-top: 20px;
}

.loginInput-title{
    font-size: 30px;
    margin-bottom: 25px;
    border-bottom: 2px solid #EE9D00;
}

.loginInput-subtitle{
    text-align: left;
    font-size: 17px;
    margin: 7px;
}

.login-input{
    padding: 15px 10px;
    border: 0;
    background-color: rgb(230, 248, 255);

    font-size: 17px;

    margin: 10px 0;

    min-width: -webkit-fill-available;
}

.forgotPassword-form{
    height: 350px
}

.form-title{
    margin-bottom: 20px;
}

.register-form{
    height: 570px;
}

.message-text{
    font-size: 20px;
    color: var(--dark-text);
}

.errorMessage-text{
    font-size: 20px;
    color: var(--error-text);
}

.message__animation{
    opacity: 1;

    animation: bounceInLeft linear 1s;
    animation-delay: 0s;
}

.message__fadeout{
    animation: fadeOut linear 1s;
}

@keyframes fadeOut{
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
}

@media screen and (max-width: 950px){
    .loginPageImage-container{
        display: none;
    }

    .loginForm-container{
        width: 50vw;
    }
}

@media screen and (max-width: 600px){
    .loginForm-container{
        width: 80vw;
    }
}