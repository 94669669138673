.error-img{
  margin: 0 auto;
  height: 200px;
  width: 200px
}

.a {
  fill: #f8de40;
}

.b {
  fill: #e7c930;
}

.c {
  fill: #864e20;
}

.d {
  fill: #f06880;
}

.errorTitle-text{
  font-size: 40px;
  width: fit-content;
  margin: 10px auto;
}