.courseListErrorMessage-container{
    margin: 25px;
}

.courseListCatalog-container{
    display: flex;
    flex-direction: row;
    max-width: 95vw;
    overflow-x: scroll;
    padding: 0 30px
}

/* Styling for WebKit browsers (Chrome, Safari) */
.courseListCatalog-container::-webkit-scrollbar {
    width: 8px;             /* Width of the scrollbar */
}
  
.courseListCatalog-container::-webkit-scrollbar-track {
    background: transparent;    /* Color of the track */
}

.courseListCatalog-container::-webkit-scrollbar-thumb {
    background-color: #adadad; /* Color of the thumb */
    border-radius: 10px;     /* Rounded corners */
}

.courseListCatalog-container::-webkit-scrollbar-thumb:hover {
    background-color: #8a8a8a; /* Darker color on hover */
}

