.learningPlanPageIntro-container{
    display: flex;

    background-color: var(--widget-grey);

    max-width: 500px;
    min-width: 500px;
    padding: 10px;

    border-radius: 10px;
}

.learningPlanPage-text{
    color: black;
    padding: 10px;
}

.learningPlanGoal-container{
    
}

.learningPlan-select{
    padding: 10px;
    border: 1px solid white;
    border-radius: 5px;
}