.custom-button:hover{
    filter: brightness(0.85); /* Adjust the value as needed */
    cursor: pointer;
}

.custom-button{
    min-width: stretch;
    margin: 10px 0;

    padding: 15px;
    border-radius: 5px;

    transition: all 0.5s linear;
}

.customButton-text{
    text-align: center;
    font-weight: 400;
    color: white;
    vertical-align: middle;
    margin: auto;
    white-space: nowrap;
}