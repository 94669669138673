.widgetInfo-container{
    padding: 20px;
}

.widgetInfo-title {
    border-bottom: 2px solid rgb(0, 0, 0, 0.5);
}

.widgetInfo-image{
    width: 300px
}