.widget-component {
    width: 300px;
    height: 325px;

    background-color: var(--widget-grey);

    border: 1px solid grey;
    border-radius: 10px;

    transition: all 0.15s linear;
}

.widget-component:hover {
    transform-origin: 0% 100%;
    transform: rotate(-3deg);
}

.widgetComponentImage-container{
    height: 50%;
}

.widgetComponent-image {
    background-color: #fca739;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48'%3E%3Cg fill='%23ffffff' fill-opacity='0.31'%3E%3Cpath fill-rule='evenodd' d='M5 3.59L1.46.05.05 1.46 3.59 5 .05 8.54l1.41 1.41L5 6.41l3.54 3.54 1.41-1.41L6.41 5l3.54-3.54L8.54.05 5 3.59zM17 2h24v2H17V2zm0 4h24v2H17V6zM2 17h2v24H2V17zm4 0h2v24H6V17z'/%3E%3C/g%3E%3C/svg%3E");
    
    
    border-top-left-radius: 8.5px;
    border-top-right-radius: 8.5px;

    width: stretch;
    height: fit-content;
}

.widgetComponentText-container{
    height: 50%;
    width: stretch;
    padding: 20px 10px;
}

.widgetComponent-header {
    font-weight: 400;
    font-size: 20px;
    margin: 3px;
}

.widgetComponent-desc {
    font-weight: 300;
    margin: 2px;
    text-align: left;
}
