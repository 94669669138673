.contact-page{
    padding: 60px 0px 0px 20px;
}

.contactTitle-container{
    margin: 20px 0;
}

.nameSubject-row{
    display: flex;
    flex-direction: row;
}

.bodyInput-container{
    background-size: 830px 45px;
    background-image: linear-gradient(90deg, transparent 50%, var(--primary-color) 50%);

    transition: background-position linear 0.3s;

    height: 100px;
    width: 415px;
}

.body-input{
    vertical-align: top;
    height: 98px;
    width: 415px;
}

.emailInput-container{
    background-size: 830px 45px;
    background-image: linear-gradient(90deg, transparent 50%, var(--primary-color) 50%);

    transition: background-position linear 0.3s;

    height: 45px;
    width: 415px;

    margin-bottom: 5px;
}

.email-input{
    height: 43px;
    width: 415px;
}

.nameInput-container{
    background-size: 400px 45px;
    background-image: linear-gradient(90deg, transparent 50%, var(--primary-color) 50%);

    transition: background-position linear 0.3s;

    height: 45px;
    width: 200px;

    margin-right: 15px;
    margin-bottom: 5px;
}

.name-input{
    height: 43px;
    width: 200px
}

.contact-description{
    margin: 8px 10px 20px 10px;
}

.contact-input{
    background: var(--widget-grey);
    border: 0;
    font-size: 12px;
    outline: none;
    padding: 10px;
}

.contactInput-invalid{
    background-image: linear-gradient(90deg, rgb(255, 0, 0) 50%, rgb(255, 0, 0) 50%);;
}

.input-boxSlideInComplete{
    background-position: -100% 0%;
}

@keyframes fadeOut{
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
}

@media screen and (max-width: 1200px){
    .contactImg-container{
        min-width: 50%;
    }

    .form-container{
        min-width: 50%;
    }
}

@media screen and (max-width: 1000px){
    .contactImg-container{
        min-width: 40%;
    }

    .form-container{
        min-width: 60%;
    }
}

@media screen and (max-width: 800px){
    .contactImg-container{
        display: none;
    }

    .form-container{
        min-width: 100%;
    }
}

@media screen and (max-width: 455px){
    .emailInput-container{
        width: -webkit-fill-available;
    }

    .email-input{
        width: -webkit-fill-available;
    }

    .bodyInput-container{
        width: -webkit-fill-available;
    }

    .body-input{
        width: -webkit-fill-available;
    }

    .name-input{
        width: 300px;
    }

    .nameInput-container{
        background-image: none;

        width: -webkit-fill-available;
        margin: 0 0 5px 0;
    }

    .contact-page{
        margin: 60px 20px 0px 20px;
    }

    .nameSubject-row{
        flex-direction: column;
    }

    .form-container{
        margin-top: 25px;
    }
}

@media screen and (max-width: 350px){
    .emailInput-container{
        width: -webkit-fill-available;
    }

    .email-input{
        width: -webkit-fill-available;
    }

    .bodyInput-container{
        width: -webkit-fill-available;
    }

    .body-input{
        width: -webkit-fill-available;
    }

    .name-input{
        width: 200px;
    }

    .form-container{
        margin-top: 50px;
    }
}