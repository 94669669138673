.dashboard-form{
    display: flex;
    flex-direction: column;

    margin: auto;
}

.dashboard-text{
    margin-bottom: 25px;
}

.dashboardWidget-container {
    gap: 20px;
    margin-top: 30px;
    max-width: 816px;
}

.dashboardPage-input{
    min-width: 557px;
}

.dashboard-subtitle{
    display: flex;
    flex-direction: row;
}

.dashboardOrangeButton-container{

}

.dashboardButton-container{
    width: 450px;
}

.dashboard-container{
    height: 100vh;
}

.dashboardPage-section1{
    margin: 0 20px 50px 20px;
}

.dashboardPage-section2{
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 150px;
}

@media screen and (max-height: 700px){
    .dashboard-container{
        height: 120vh;
    }
}

@media screen and (max-width: 500px){
    .dashboardButton-container{
        width: 80%;
    }
}

@media screen and (max-width: 600px){
    .dashboardPage-input{
        min-width: -webkit-fill-available;
        margin: 0 50px;
    }
}

@media screen and (max-width: 800px) {
    .dashboardForm{
        width: 260px;
    }

    .dashboardInput{
        width: 140px;
    }

    .dashboard-subtitle{
        flex-direction: column;
    }

    .dashboardOrangeButton-container{
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 1266px) and (min-width: 800px) {
    .dashboardForm {
        width: 600px;
    }

    .dashboardInput{
        width: 413px;
    }
}

@media screen and (min-width: 1266px) {
    .dashboardForm {
        width: 820px;
    }

    .dashboardInput{
        width: 685px;
    }
}