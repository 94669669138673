.overlay-container {
    z-index: 99;
    position: fixed;
    min-height: 100%;
    min-width: 100%;
    background-color: rgb(55, 55, 55, 0.5);
    text-align: center;
    line-height: 100vh;
}

/* Keyframes for the wave effect */
@keyframes wave {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
}
  
.loading-wave {
    display: inline-flex;
    font-size: 4rem;
    color: #ffffff;
    font-family: monospace;
}

.loading-wave span {
    display: inline-block;
    animation: wave 2s infinite;
}

.loading-wave span:nth-child(1) {
    animation-delay: 0s;
}

.loading-wave span:nth-child(2) {
    animation-delay: 0.1s;
}

.loading-wave span:nth-child(3) {
    animation-delay: 0.2s;
}

.loading-wave span:nth-child(4) {
    animation-delay: 0.3s;
}

.loading-wave span:nth-child(5) {
    animation-delay: 0.4s;
}

.loading-wave span:nth-child(6) {
    animation-delay: 0.5s;
}

.loading-wave span:nth-child(7) {
    animation-delay: 0.6s;
}

.loading-wave span:nth-child(8) {
    animation-delay: 0.7s;
}

.loading-wave span:nth-child(9) {
    animation-delay: 0.8s;
}

.loading-wave span:nth-child(10) {
    animation-delay: 0.9s;
}