.home-page{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 700px;
    background-image: linear-gradient(var(--primary-color), var(--secondary-color));
}

.title-text{
    width: fit-content;
    height: fit-content;
    color: var(--light-text);
    font-weight: 600;
}

.title{
    margin-top: 100px;
    font-size: 48px;
}

.video{
    margin-top: 50px;
    height: 320px;
    width: 480px;
}

.subtitle{
    color: var(--dark-text);
    margin: 25px 0;
    font-size: 36px;
}

.paragraph{
    font-size: 20px;
    line-height: 30px;
}

.introduction{
    padding-top: 50px;
}

.facts{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding-left: 0;
    list-style: none;
    justify-content: space-between;
    margin: 40px 0;
}

.fact{
    font-size: 40px;
    font-weight: 500;
    display: block;
    color: var(--primary-color);
}

.color-text{
    background: linear-gradient(var(--primary-color), var(--secondary-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    word-spacing: 2px;
    font-family: 'Poppins', sans-serif;
}

.widget-container{
    max-width: 800px;
    margin: 3rem auto;
    padding: 0 20px;
}

.widgetText-container{
    padding: 0 20px;
}

.widget-title{
    font-size: 24px;
    margin: 7px 0;
    font-weight: 600;
}

.widgetImg-container{
    display: flex;
    justify-content: center;
}

.widget-img{
    border-radius: 10px;
    height: 400px;
    width: 400px;
}

.button{
    margin: 20px;
    background: linear-gradient(var(--primary-color), var(--secondary-color));
    color: var(--light-text);
    font-weight: 400;
    padding: 10px 20px;
    border-radius: 30px;
    transition: background-color 2s ease-in-out;
}

.button:hover{
    background: linear-gradient(90deg, var(--primary-color), var(--secondary-color));
}

.homePageErrorMessage-container{
    margin: 25px;
}

.homePageCatalog-container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);

}


@media screen and (min-width: 525px){
    .facts {
        grid-template-columns: repeat(3,1fr);
    }
}

@media screen and (max-width: 700px){
    .widget-container{
        flex-direction: column;
    }

    .homePageCatalog-container{
        grid-template-columns: repeat(1, 1fr);
    }

    .widgetImg-container{
        margin: -60px 0;
    }

    .widget2-container{
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 1300px) and (min-width: 700px)
{
    .homePageCatalog-container{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 400px)
{
    .widget-img{
        height: 350px;
        width: 350px;
    }

}

@media screen and (max-width: 600px)
{
    .widgetText-container{
        text-align: center;
    }

    .introduction{
        padding-top: 25px;
        padding-bottom: 15px;
    }
}






