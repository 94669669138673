.greyBackground-image{
    background-image: url(../../imgs/CatalogPageBackground.svg);
    min-width: -webkit-fill-available;
    min-height: 884px;
    max-height: 884px;
}

.catalogPage-input{
    min-width: 557px;
}

.catalogPage-section1{
    margin-bottom: 50px;
}

.catalogPage-section2{
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 150px;
}
