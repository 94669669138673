
.subtitle-container{
    display: flex;
    flex-direction: row;
}

.catalogLanding-section2{
    display: flex;
    flex-direction: row;
}

.catalogLanding-video{
    width: 50%;
    max-width: 820px; /* For larger screens 50% overlaps */
    padding: 0 50px 0 0;
}

.tryAFew-container:hover {
    background-color: rgb(169, 110, 0);
}

@media screen and (max-width: 1000px){
    .catalogLanding-section2{
        flex-direction: column;
    }

    .catalogLanding-video{
        width: 450px;
        padding: 0 0 50px 0;
    }
}

@media screen and (max-width: 575px){
    .catalogLanding-video{
        width: 400px;
    }
    .catalogLanding-section2{
        margin: auto;
    }
}

@media screen and (max-width: 780px){
    .catalogLanding-video{
        display: none;
    }
}

@media screen and (max-width: 450px){
    .subtitle-container{
        flex-direction: column;
    }

    .everything-margin{
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 700px)
{
    .catalogLanding-section3{
        margin-bottom: 700px;
    }
}


@media screen and (min-width: 700px)
{
    .catalogLanding-section3{
        margin-bottom: 10px
    }
}