.googleAuth-container{
    border: 1px solid rgb(176, 176, 176);
    padding: 0 30px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.googleAuth-container:hover{
    cursor: pointer;
    background: #e7e7e7;
}